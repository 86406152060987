@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-BlackItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-Regular.ttf) format("truetype");
}

/* .videoImage {
  position: relative;
  right: 0;
  background-color: rgba(255, 183, 172, .11);
  border-radius: 50% !important;
  animation: vdo-btn-two 1.5s linear infinite;
  animation-duration: 1s !important;
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes vdo-btn-two {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 183, 172, .11), 0 0 0 10px rgba(255, 183, 172, .11), 0 0 0 30px rgba(255, 183, 172, .11);
  }

  50% {
    box-shadow: 0 0 0 10px rgba(255, 183, 172, .11), 0 0 0 30px rgba(255, 183, 172, .11), 0 0 0 50px rgba(255, 183, 172, 0);
  }
} */

/* @keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
} */
.fadeIn {
  animation-name: fadeIn;
  animation-duration: 1s;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}


@keyframes slideBottom {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

/* @keyframes slidein {
  from {
    translate: 60vw 0;
    scale: 200% 1;
  }

  to {
    translate: 0 0;
    scale: 100% 1;
  }
} */

@keyframes slideRight {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}



.slideUp {
  animation-name: slideUp;
  animation-duration: 1s;
}

@keyframes slideUp {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}



.textarea {
  background: #fafafa;
  border-radius: 4px;
  width: 100%;
  height: 120px;
  resize: none;
  line-height: 28px;
  font-size: 16px;
  padding:12px 20px;
  color: #696969;
  border: none;
  margin-bottom: 10px;
  transition: all .3s ease-in-out #6f39c2;
  -webkit-transition: all .3s ease-in-out;
  border: 1px solid #d0dcec;
}


.animate-marquee{
  display: flex;
  animation: marquee 15s linear infinite;
}
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@layer components {
  body {
    font-family: "Poppins";
  }
  h3{
    @apply text-[42px] uppercase text-center font-bold block float-left text-[#3E3E3E]
  }
  h4{
    @apply text-base uppercase text-center font-medium block float-left text-[#3E3E3E]
  }
  .Icons {
    @apply w-5 h-5 object-contain
  }

  p {
    @apply text-justify text-base text-[#3e3e3e] font-light float-left
  }
  
  input{
    @apply  bg-[#FAFAFA] rounded w-full h-[55px] leading-[55px] float-left p-3 bottom-[10px] text-[#696969] border border-[#d0dcec] outline-none focus:border hover:border-black transition-all duration-200 focus:border-black
  }
  input::placeholder{
  @apply text-[#777877]
  }
  .RoundBox {
    @apply w-[95px] h-[65px] rounded-full text-[25px] leading-[65px] text-white text-center float-left bg-gradient-to-r from-[#30077d] to-[#0f51bf]
  }

}
